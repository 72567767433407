$(document).ajaxError(function (event, xhr, settings, thrownError) {
    if (xhr.status !== 422) {
        return error(thrownError)
    }
    var errors = JSON.parse(xhr.responseText)
    $.each(errors, function (i, o) {
        error(o.message)
    })
})
$.ajaxSetup({cache: false});
(function ($) {
    $.fn.countdown = function () {
        const self = $(this)
        const span = self.find('span')
        const dateEnd = self.data('end')

        if (!dateEnd || !span.length) {
            return this
        }

        function getTimeRemaining() {
            const serverTime = 60000 + self.data('time')

            const t = dateEnd - serverTime
            self.data('time', serverTime)

            return {
                'total': t,
                'times': {
                    'day': Math.floor(t / (1000 * 60 * 60 * 24)),
                    'hr': Math.floor((t / (1000 * 60 * 60)) % 24),
                    'min': Math.floor((t / 1000 / 60) % 60)
                }
            }
        }

        const interval = setInterval(function () {
            const period = getTimeRemaining(), content = []
            for (const i in period.times) {
                if (period.times[i] > 0) {
                    content.push(period.times[i] + ' ' + i)
                }
            }
            if (content.length) {
                span.html(content.join(' : '))
            }
            if (period.total <= 0) {
                clearInterval(interval)
                span.parent().remove()
            }
        }, 60000)
        return this
    }
}(jQuery))
$(document).ready(function () {
    /* --- global --- */
    $.validator.setDefaults({
        focusInvalid: false,
        invalidHandler: function (form, validator) {
            var errors = validator.numberOfInvalids(),
                errorList = validator.errorList
            if (!errors || !errorList) {
                return true
            }
            for (var i in errorList) {
                var el = errorList[i].element, fe = el
                if ($(el).is(':radio') || $(el).is(':checkbox')) {
                    if ($(el).parent()[0].tagName === 'LABEL') {
                        fe = $(el).parent()
                    }
                }
                $(fe).caption(errorList[i].message, {align: 'top', color: 'red'}).addClass('c-red')
            }
            if (fe) {
                fe.focus()
            }

        },
        showErrors: function () {
            if (this.successList.length) {
                for (var j in this.successList) {
                    $(this.successList[j]).removeClass('c-red').caption(':remove')
                    $(this.successList[j]).parent().removeClass('c-red').caption(':remove')
                }
            }
        }
    })
    $.validator.addMethod('excludePOAPO', function (value, element) {
        var poReg = /(\s|^)(PO BOX|POBOX|PO|P.O|P.O.|P.O.BOX|PO-BOX|BOX)(\s|$)/i
        var apoReg = /(\s|^)(APO BOX|APOBOX|APO|A.P.O|A.P.O.|A.P.O.BOX|APO-BOX)(\s|$)/
        return this.optional(element) || (!poReg.test(value) && !apoReg.test(value))
    }, 'We don\'t ship to PO or APO addresses')
    $('.phone').mask('(999) 999-9999')
    $.validator.addClassRules('excludePOAPO', {excludePOAPO: true})
    $(document).on('submit', 'form', function () {
        return $(this).valid()
    })
    $('.digits').keypress(function (e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
            return false
        }
    })
    $('.flash-notice').each(function (i, o) {
        message($(o).html(), $(o).data('type'))
    })
    if ($('#flashErrors').length) {
        popup('#flashErrors')
    }
    $('.oneClick').on('click', function (e) {
        if (!$(this).hasClass('isClicked')) {
            $(this).addClass('isClicked')
        } else {
            alert('Please reload page and try again')
            e.preventDefault()
        }
    })
    $(document).on('click', '.popup .close', function () {
        return closePopup(this)
    })
    $('.overlay').click(function () {
        if (!$('.popup-fixed').length) {
            return fadeOut('.overlay', '.popup')
        }
    })
    if ($('.custom-select').length) {
        var $select = $('.custom-select').find('select')
        if ($select.length) {
            $.each($select, function () {
                if ($(this).val() != 0 && $(this).val() != '') {
                    $(this).closest('.custom-select').find('span').html($(this).find('option:selected').text())
                }
            })
        }
    }
    $(document).on('change', '.custom-select select', function () {
        $(this).parent().find('span').html($('option:selected', this).html())
    })
    $(document).on('focus', '.custom-select select', function () {
        $(this).closest('.custom-select').addClass('focused')
    })
    $(document).on('blur', '.custom-select select', function () {
        $(this).closest('.custom-select').removeClass('focused')
    })
    $(document).on('click', '.styled-select li:not(.item-group)', function () {
        $(this).closest('ul').hide().find('li').removeClass('active')
        $(this).addClass('active').closest('.styled-select').find('span').html($(this).html())

        var input = $(this).closest('.styled-select').find('input[name=\'size\']'), val = $(this).data('id')
        if (input.length) {
            input.val(val).trigger('change')
        }
    })
    $(document).on('click', '.styled-select div', function () {
        var ul = $(this).parent().find('ul'),
            callback = function () {
                var active = ul.find('.active')
                if ($(active).length) {
                    ul.scrollTop($(active)[0].offsetTop - 90)
                }
            }
        ul.show()
        if (ul.hasClass('js-load-sizes')) {
            var params = {
                product: ul.data('product'),
                selected: ul.data('selected')
            }
            $.get('/product-sizes/', params, function (result) {
                ul.html($(result).find('li'))

                callback()
            })
        } else {
            callback()
        }
    })
    $(document).click(function (e) {
        var el = $('.styled-select')
        if (el.has(e.target).length === 0) {
            el.find('ul').hide()
        }
    })
    $(document).on('click', '#product-size li', function () {
        var val = $(this).data('id')
        $('.creation-link').each(function () {
            var link = $(this).prop('href').split('?'), size = 'size=' + val, exists = false,
                parts = link[1] ? link[1].split('&') : []
            for (var i in parts) {
                if (parts[i].indexOf('size') == 0) {
                    parts[i] = size
                    exists = true
                }
            }
            if (!exists) {
                parts.push(size)
            }
            $(this).prop('href', link[0] + '?' + parts.join('&'))
        })
        if ($('.qnt-discounts').length) {
            var price = $(this).data('price'), product = $('input[name=product]').val()
            $.get('/ajax/discounts/', {price: price, product: product}, function (result) {
                $('.qnt-discounts').html(result)
            })
        }
        if ($('.size-qnt').length) {
            $.get('/ajax/size-qnt/', {size: val}, function (result) {
                $('.size-qnt').html(result)
            })
        }
        if ($('.landing-discounts').length) {
            var product = $('input[name=product]').val()
            $.get('/ajax/landing-price/', {size: val, product: product}, function (price) {
                if (price) {
                    $('#size-price').html('$' + price)
                    $.get('/ajax/discounts/', {
                        price: price,
                        product: product,
                        prefix: $('.landing-discounts').data('prefix') || 'landing-'
                    }, function (result) {
                        $('.landing-discounts').html(result)
                    })
                }
            })
        }
        if ($('input[name=size]').length) {
            $('input[name=size]').val(val)
            submit(this)
        }
    })
    $('#product-package li').click(function () {
        var val = $(this).data('id')
        $('.creation-link').each(function () {
            var link = $(this).prop('href').split('?'), size = 'size=' + val, exists = false,
                parts = link[1] ? link[1].split('&') : []
            for (var i in parts) {
                if (parts[i].indexOf('size') == 0) {
                    parts[i] = size
                    exists = true
                }
            }
            if (!exists) {
                parts.push(size)
            }
            $(this).prop('href', link[0] + '?' + parts.join('&'))
        })
        if ($('.qnt-discounts').length) {
            $.get('/ajax/packages/', {size: val}, function (result) {
                $('.qnt-discounts').html(result)
            })
        }
    })
    $('.qnt-discounts').click(function () {
        $(this).children('div').toggle()
    })

    $(document).on('click', '.js-content-toggle', function () {
        var self = $(this), target = $(self.data('target'))
        if (!target.length) {
            return
        }
        if (self.hasClass('js-open')) {
            self.removeClass('js-open')
            return target.hide()
        }
        self.addClass('js-open')
        return target.show()
    })

    $(document).on('change', '.js-load-slider', function (e) {
        var path = $(this).find('option:selected').data('slider')
        if (!path) return
        $.get(path, function (result) {
            $('.product-slider').html($(result).html())
        })
    })

    $(document).on('click', '.js-button-slider', function () {
        var path = $(this).data('slider')
        if (!path) return
        $.get(path, function (result) {
            $('.product-slider').html($(result).html())
        })
    })

    $('input[type=search],input[type=text]').focus(function () {
        var sign = $(this).data('sign')
        if (sign && sign === $(this).val()) {
            $(this).val('')
        }
    }).blur(function () {
        var sign = $(this).data('sign')
        if (!$(this).val() && sign) {
            $(this).val(sign)
        }
    })
    $('.promo-form').submit(function (e) {
        e.preventDefault()
        var self = $(this)
        $.post(self.prop('action'), self.serialize(), function (result) {
            if (result.cssClass) {
                self.addClass(result.cssClass)
                self.find('input[type=text]').val(result.message)
                self.find('input[type=submit]').prop('disabled', 'disabled')
                if (result.cssClass == 'accept') {
                    if (self.find('a').length) {
                        self.find('a').show()
                    }
                }
            }
        })
    })

    $('.promo-form input[type=text]').focus(function () {
        if ($(this).closest('form').hasClass('error')) {
            $(this).val('').closest('form').removeClass('error').find('input[type=submit]').removeAttr('disabled')
        }
    })
    $('.tabs .tab').click(function () {
        var section = $('.' + $(this).attr('id') + '_content')
        $('.tabs .tab').removeClass('active')
        $(this).addClass('active')
        if (section.length) {
            section.insertAfter($('.tabs > div'))
        }
    })
    $('.js-download-href').on('click', function () {
        const self = $(this)
        const href = self.data('href')

        if (!href) {
            return
        }

        const link = document.createElement('a');
        link.setAttribute('download', basename(href));
        link.setAttribute('target', '_blank');

        link.href = href;

        link.click();
    })
    /* --- end global --- */
    /* --- auth --- */
    $(document).on('submit', '.auth form', function (e) {
        e.preventDefault()
        if (!$(this).valid()) {
            return false
        }
        var $this = $(this)
        flushCaptions($this)
        $.post($this.prop('action'), $this.serialize(), function (result) {
            if (result && result.errors) {
                return showAuthErrors($this, result.errors)
            }
            if (referrer) {
                return window.location.href = referrer
            }
            window.location.reload()
        }, 'json')
    })
    $(document).on('submit', '.recover form', function (e) {
        e.preventDefault()
        if (!$(this).valid()) {
            return false
        }
        var $this = $(this)
        flushCaptions($this)
        $.post($this.prop('action'), $this.serialize(), function (result) {
            if (result.errors) {
                return showAuthErrors($this, result.errors)
            }
            if (result.success) {
                dialog($('.recover #success').html())
            }
        }, 'json')
    })
    /* --- end auth --- */
    /* --- designer --- */
    $(document).on('click', '.btnSubmitDesign', function (e) {
        if ($(this).attr('disabled')) {
            return false
        }
        if ($('#doSave').length) {
            $('#doSave').val($(this).data('save'))
        }
        if ($('.design-submit').length) {
            return popup('.design-submit')
        } else {
            return true
        }
    })
    /* --- end designer --- */
    /* --- upsell --- */
    $(document).on('click', '.upsell .options dl', function () {
        $('.upsell .options dl').removeClass('active')
        var id = $(this).addClass('active').find('input[type=radio]').prop('checked', 'checked').prop('id').replace(/option/, '')
        if ($('.upsell .sides').length) {
            $('.upsell .sides').hide()
            $('.upsell .sides#option_preview_' + id).show()
        }
    })
    /* --- end upsell --- */
    $('.suggester-items .detail').click(function () {
        var div = $(this).closest('div')
        $('.suggester-items > div').removeClass('active')
        var index = 0, position = div.index()
        if (position > 2) {
            index += 3
        }
        if (position > 5) {
            index += 3
        }
        if (position != index) {
            div.insertBefore($('.suggester-items > div')[index])
        }
        div.addClass('active', {duration: 800, easing: 'easeOutCubic'})
    })
    $('.suggester-items .cancel').click(function () {
        $(this).closest('div').removeClass('active', {children: true, duration: 700, easing: 'easeOutCubic'})
    })

    if ($('#product-size').length) {
        $(document).click(function (e) {
            var s = $(e.target).closest('#product-size')
            if (!$(s).length) {
                var ul = $('#product-size').find('ul')
                if ($(ul).is(':visible')) {
                    $(ul).hide()
                }
            }
        })
    }
    if ($('#product-package').length) {
        $(document).click(function (e) {
            var s = $(e.target).closest('#product-package')
            if (!$(s).length) {
                var ul = $('#product-package').find('ul')
                if ($(ul).is(':visible')) {
                    $(ul).hide()
                }
            }
        })
    }
    if ($('.qnt-discounts').length) {
        $(document).click(function (e) {
            var s = $(e.target).closest('.qnt-discounts')
            if (!$(s).length) {
                $('.qnt-discounts').children('div').hide()
            }
        })
    }

    if ($('.discount-countdown')) {
        $('.discount-countdown').countdown()
        $(document).on('click', '.discount-countdown #code-details', function () {
            $(this).remove()
        })
    }
    $('#size-products-price').change(function () {
        var selected = $(this).find('option:selected')
        $('.product-size-price').html(selected.data('price'))
        $('#designer-link').prop('href', selected.data('designer'))
        $('#uploader-link').prop('href', selected.data('uploader'))
    })
    $(document).on('submit', '.shipping-rates-form', function (e) {
        e.preventDefault()
        if ($(this).find('[name=zip]').val() == '') {
            return false
        }
        $('.js-rates-loader').show()
        $('.shipping-rates-available').html('')
        if (!$(this).valid()) {
            $('.js-rates-loader').hide()
            return false
        }
        $.post('/shipping/product-size-zip-rates/', $(this).serialize(), function (result) {
            $('.js-rates-loader').hide()
            $(result).each(function (i, o) {
                $('.shipping-rates-available').append('<dl><dt>' + o.caption + '</dt><dd>' + o.price + ' / ' + o.days + '</dd></dl>')
            })
        }).fail(function () {
            $('.js-rates-loader').hide()
        })
    })

    $('#priceDiscover').submit(function (e) {
        e.preventDefault()

        const productUrl = $(this).find('select').val()
        if (productUrl) {
            window.location.href = productUrl
        }
    })

    $(document).on('change', 'select', function () {
        const id = $(this).find('option:selected').data('slider')

        id && showSelectedSlide(id)
    })

    $(document).on('click', '.product-slider-thumbs picture', function () {
        const self = $(this)
        showSelectedSlide(self.data('slide'))


        const container = $(this).closest('.product-slider-thumbs-wrapper')

        const scrollValue = self.index() * 75 - container.width() / 2 + 75 / 2;
        container.animate({scrollLeft: scrollValue}, 300);
    })

    $(document).on('click', '.js-store-options', function (e) {
        e.preventDefault()
        $(this).prop('disabled', 'disabled')
        var self = this, form = $(this).closest('form')

        $.post(form.prop('action'), form.serialize(), function () {
            $(self).prop('disabled', false)
            $.get('/cart/update/', $('#cart .general').serialize(), function (cart) {
                $('#cart').html(cart)
                calcGroupsAmountTotal()
            })
            closePopup(self)
        })
    })

    $('.tabs > a').click(function () {
        var tab = $(this).data('tab')
        $(this).closest('.tabs').find('a').removeClass('active')
        $(this).addClass('active')
        if ($(tab).length) {
            $('.tab-content').hide()
            $(tab).show()
        }
    })


    $('.top-menu-burger').on('click', function () {
        $(this).toggleClass('active')
        $('.top-menu').toggleClass('active')
    })

    let shouldShowSubMenu = false
    const topSubMenu = $('.top-menu-submenu')
    const topSubMenuSwitcher = $('.top-menu-submenu-switcher')
    const hideMenuOnLeave = () => {
        shouldShowSubMenu = false
        setTimeout(() => {
            !shouldShowSubMenu && topSubMenu.hide()

            topSubMenuSwitcher.removeClass('js-open')
        }, 1000)
    }
    topSubMenu
        .on('mouseenter', () => shouldShowSubMenu = true)
        .on('mouseleave', hideMenuOnLeave)

    topSubMenuSwitcher
        .on('mouseenter', (e) => {
            topSubMenu.fadeIn('fast')
            shouldShowSubMenu = true
            topSubMenuSwitcher.addClass('js-open')
        })
        .on('mouseleave', hideMenuOnLeave)


    if (window.innerWidth <= 1024) {
        $('.top-menu-submenu-item-title').on('click', function () {
            const self = $(this)

            self.parent().find('.top-menu-submenu-item-links').slideToggle()
            self.toggleClass('is-opened')
        })
    }

    $(document).on('click', '.js-amount-group-caption', function () {
        var parent = $(this).parent()[0], group = $(this).data('group')
        $('.js-amount-group').each(function (i, obj) {
            if (parent === obj) {
                $(obj).addClass('selected')
                $('#amountSelectedGroup').val(i)
                return
            }
            $(obj).removeClass('selected')
        })
    })

    const bannerWidgetContainer = $('#bannerWidgetContainer')
    if (bannerWidgetContainer.length) {
        $.get('/widget/banner/', (data) => {
            bannerWidgetContainer.append(data)
        })
    }

    calcGroupsAmountTotal()

    $('.footer-blocks-links-column-title').on('click', function () {
        $(this).parent().find('.footer-blocks-links-column-items').slideToggle()
    })
})